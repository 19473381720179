import { useState } from "react";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import "./style.css";
import { getPasswordNotation } from "../../helpers/passwordChecker";
import { RemoveRedEye } from "@mui/icons-material";

const Input = ({
  isValid,
  label,
  name,
  errorMsg = "",
  type = "text",
  checker = false,
  onChange,
  placeholder,
  size,
  defaultValue = "",
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(errorMsg);
  const [showPassword, setShowPassword] = useState(false);

  const changeValue = (evt) => {
    setValue(evt.currentTarget.value);
    if (onChange) onChange(evt.currentTarget.value);
  };
  return (
    <div
      className={`WU-input ${focus || !!value ? "focus" : ""} ${
        error ? "error" : ""
      } ${size}`}
    >
      <label onClick={() => setFocus(true)}>
        {label} {isValid && <CheckCircleOutline fontSize="8px" />}
      </label>
      {type === "password" && (
        <RemoveRedEye
          onClick={() => setShowPassword(!showPassword)}
          className={`remove-eye ${showPassword ? "active" : "non-active"}`}
        />
      )}
      <input
        className="input-field"
        placeholder={placeholder}
        onChange={changeValue}
        value={value}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        type={showPassword ? "text" : type}
        name={name}
      />
      {checker && <PasswordChecker value={value} />}
    </div>
  );
};

const PasswordChecker = ({ value }) => {
  const note = getPasswordNotation(value);

  return (
    <div className="PasswordChecker">
      {[...new Array(4)].map((_, i) => (
        <div
          key={i}
          className={`PasswordChecker-item ${i < note ? "active" : ""}`}
        />
      ))}
    </div>
  );
};

export default Input;
