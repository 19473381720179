import { Button, Card } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import api, { UnAuthorizeError, publicApi } from "../../../api"
import { useParams } from 'react-router-dom';
import Input from "../../../components/Input";
import Error from "../../../components/Error";
import { useContext, useState } from "react";
import { ToastContext } from "../../../components/Toast";
import { Trans, useTranslation } from "react-i18next";


const userHasActivatedWedding = (user) => user?.Wedding?.enabled
const userHasUnactivatedWedding = (user) => !!user?.Wedding && (!user?.Wedding?.enabled || !user?.Wedding?.paid)
const userHasWedding = (user) => !!user?.Wedding
const userHasInvitations = (user) => user?.invitations?.length > 0


const Login = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [error, setError] = useState("")
    const {addToast} = useContext(ToastContext);
    const {lang="en"} = useParams()
    const login = async (evt) => {
        evt.preventDefault()
        setError("")
        const { email, password } = evt.target
        try {
            const loginInfo = await api.public.users.login({
                username: email.value,
                password: password.value,
            })
            publicApi.setToken(loginInfo["access_token"])
            localStorage.setItem("wu-jwt", loginInfo["access_token"]);
            const profil = await api.public.users.profil()
            const user = await api.public.users.getUser(profil.userId);
            addToast({
                message: t("Your are connected!"),
                type: "success",
            })

            if(userHasActivatedWedding(user)) {
                navigate(`/${lang}/${user.Wedding.id}/dashboard/photos`)
                return
            }

            if (userHasUnactivatedWedding(user)) {
                navigate(`/${lang}/create/${user.Wedding.id}/choose-offer`)
                return
            }

            if (!userHasWedding(user) && userHasInvitations(user)) {
                navigate(`/${lang}/invitations`)
                return
            }

            if(!userHasWedding(user) && !userHasInvitations(user)) {
                navigate(`/${lang}create/name`)
                return
            }

            navigate('/not-found')

        } catch (e) {
            if (e instanceof UnAuthorizeError) {
                addToast({
                    message: t("Wrong credentials, try again!"),
                    type: "error",
                })
            }
        }

    }
    return <form className="form" method="post" onSubmit={login}>
        <Error errorMsg={error} />
        <Card className="formContainer">
            <h2><Trans>Fill your credentials</Trans></h2>
            <Input placeholder={t("Enter your email address")} label={t("Email")} name="email" />
            <Input placeholder={t("Enter your password")} type="password" label={t("Password")} name="password" />
            <Link to={`/${lang}/authentication/reset`} href=""><Trans>Password missing ?</Trans></Link>
            <Button type="submit" color="success" variant="contained"><Trans>Login</Trans></Button>
            <a href={`/${lang}/authentication/register`} className="Button Button--secondary"><Trans>Create an account</Trans></a>
        </Card>
    </form>
}

export default Login