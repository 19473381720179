import { useEffect, useState } from "react"

const cache = {}

export const useFetch = (request, options) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false);

    const execQuery = async (force = false) => {
        if (!force && (error || data || loading)) return
        setLoading(true)
        try {
            const result = await request()
            setLoading(false)
            setData(result)
            if(options.cached) {
                cache[options.cacheKey] = result;
            }
        } catch (error) {
            setLoading(false)
            setError(error)
        }
    }

    const reload = () => {
        execQuery(true)
    }

    const loadCache = (key) => {
        setData(cache[key])
        setLoading(false)
    }

    useEffect(() => {
        if(options?.cached && cache[options.cacheKey]) {
            try {
                loadCache(options.cacheKey)
            } catch(error) {
                execQuery()
            }
        } else {
            execQuery()
        }
    }, [data, execQuery, options, reload])
    return {
        error,
        loading,
        data,
        reload,
    }
}
