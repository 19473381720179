import { Button, Card, TextField } from "@mui/material";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import Stepper from "../../../components/Stepper";
import api from "../../../api";
import { Suspense, useContext, useState } from "react";
import { useFetch } from "../../../hooks";
import "./style.css"
import { Trans, useTranslation } from "react-i18next";
import { ToastContext } from "../../../components/Toast";

const ChooseOffer = () => {
  const {addToast} = useContext(ToastContext);
  const [cvgValidated, setCgvValidated] = useState(false)
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [offer, setOffer] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const { data: offers, loading, error } = useFetch(() => api.public.offers.list());
  const { id } = useParams();
  const createContract = async (evt) => {
    evt.preventDefault();
    try {
      const profilUser = await api.public.users.profil();
      const contract = await api.public.contracts.create({
        offerId: offer,
        userId: profilUser.userId,
        weddingId: id,
      });
      if (contract.paid) {
        addToast({
          message: t("Thanks for order! Enjoy your moment!"),
          type: "success"
        })
        navigate(`/${id}/dashboard`);
      } else {
        addToast({
          message: t("Thanks for order! You will be redirected to our payment provider"),
          type: "success"
        })
        setPaymentProcessing(true)
        setTimeout(() => {
          window.location.href = contract.paymentUrl;
        }, 3000)
      }
    } catch (e) {
      addToast({
        message: t("unable to create a contract on that offer"),
        type: "error"
      })
      console.error(e);
      throw e
    }
  };
  const sortedOffers = offers?.sort((offerA, offerB) => offerA.amount < offerB.amount ? -1 : 1)
  return (
    <form className="form" method="post" onSubmit={createContract}>
      <Stepper number={3} start={3} mutable={false} />
      <Suspense fallback={<div><Trans>Offers are loading</Trans></div>}>
        <Card className="formContainer">
          <div className="offers-container">
            {!loading &&
              sortedOffers?.map(item => <div key={item.id}
                className={`Offer ${offer === item.id
                  ? "selected"
                  : ""
                  }`}
                onClick={() => setOffer(item.id)}
              >
                {item.type === "FREE" && <Free price={item.amount} />}
                {item.type === "PREMIUM" && <Premium price={item.amount} />}
                {item.type === "GOLD" && <Gold price={item.amount} />}
              </div>
              )}
          </div>
          <div className="cgv-input">
            <input type="checkbox" onChange={(evt) => setCgvValidated(evt.currentTarget.checked)} />
            <Trans>I accept the commercial condition</Trans>
            <a href="/cgv.pdf" target="_blank">
              <Trans>Read the cgv</Trans>
            </a>
          </div>
          <Button
            type="submit"
            disabled={!offer || !cvgValidated || paymentProcessing}
            color="success"
            variant="contained"
          >
            <Trans>Choose this offer</Trans>
          </Button>
          <a href="/authentication/login"><Trans>Cancel</Trans></a>
        </Card>
      </Suspense>
    </form>
  );
};

const priceText = (price) => {
  return `${(price/100).toFixed(2).replace('.', ',')}€`
}

const Free = ({price}) => <>
  <h2><Trans>{!price ? "FREE" : priceText(price)}</Trans></h2>
  <p>
    <div className="OfferDetail"><Trans>Storage: 1Go</Trans></div>
    <div className="OfferDetail__important"><Trans>+/- 200 pictures</Trans></div>
    <div className="OfferDetail"><Trans>10 guests</Trans></div>
    <div className="OfferDetail"><Trans>1 album download</Trans></div>
  </p>
</>


const Premium = ({price}) => <>
  <h2>{!price ? "PREMIUM" : priceText(price)}</h2>
  <p>
    <div className="OfferDetail"><Trans>Storage: 100Go</Trans></div>
    <div className="OfferDetail__important"><Trans>+/- 20,000 pictures</Trans></div>
    <div className="OfferDetail"><Trans>100 guests</Trans></div>
    <div className="OfferDetail"><Trans>♾️ album downloads</Trans></div>
  </p>
</>


const Gold = ({price}) => <>
  <h2>{!price ? "GOLD" : priceText(price)}</h2>
  <p>
    <div className="OfferDetail"><Trans>Storage: 1To</Trans></div>
    <div className="OfferDetail__important"><Trans>more than 100,000 pictures</Trans></div>
    <div className="OfferDetail"><Trans>♾️ guests</Trans></div>
    <div className="OfferDetail"><Trans>♾️ album downloads</Trans></div>
  </p>
</>

export default ChooseOffer;
