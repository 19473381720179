import { Button, Card, TextField } from "@mui/material"
import { useNavigate} from "react-router-dom"
import api from "../../../api"
import Stepper from "../../../components/Stepper"
import { Trans, useTranslation } from "react-i18next"



const WeddingName = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const createWedding = async (evt) => {
        evt.preventDefault()
        const { weddingName } = evt.target
        try {
            const profilUser = await api.public.users.profil()
            const wedding = await api.public.wedding.create({
                name: weddingName.value,
                ownerId: profilUser.userId,
            })
            navigate(`/create/${wedding.id}/choose-offer`)
        } catch (e) {
            console.error(e)
            throw e
        }
    }
    return <form className="form" method="post" onSubmit={createWedding}>
        
        <Stepper number={3} start={2} mutable={false} />
        <Card className="formContainer">
            <TextField label={t("Name of your wedding")} name="weddingName" fullWidth />
            <Button type="submit" color="success" variant="contained"><Trans>Create</Trans></Button>
        <a href="/authentication/login"><Trans>Cancel</Trans></a>
        </Card>
    </form>
}

export default WeddingName