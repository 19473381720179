import { useRef, useState } from "react"

import "./style.css"
import { ALLOWED_TYPES, useUploader } from "../../contexts/Upload"
import { Trans } from "react-i18next"


const DragNDropFiles = ({weddingId, children}) => {
    const ref = useRef()
    const {addFiles} = useUploader();
    const [dragging, setDragging] = useState(false)
    const preventDefault = (evt) => evt.preventDefault()

    const onDragOver = (evt) => {
        evt.preventDefault()
        evt.stopPropagation()
        if (ref.current === evt.currentTarget) {
            setDragging(true)
        }
    }

    const onDragLeave = (evt) => {
        evt.preventDefault()
        evt.stopPropagation()
        if (ref.current === evt.currentTarget) {
            setDragging(false)
        }
    }

    const onDrop = (evt) => {
        evt.preventDefault()
        evt.stopPropagation()
        if (ref.current === evt.currentTarget) {
            const toUpload = [...evt.dataTransfer.items].map(item => item.kind === "file" ? item.getAsFile() : {}).filter(file => ALLOWED_TYPES.includes(file.type))
            addFiles(weddingId, toUpload)
            setDragging(false)
        }
    }


    return <form ref={ref} className={`DragNDropContainer ${dragging ? "DragNDropContainer--dragging" : ""}`} onDrop={onDrop} onSubmit={preventDefault} onDragLeave={onDragLeave} onDragOver={onDragOver}>
        {!dragging && children}
        {dragging && <div className="DragNDropBox">
            <Trans>
                Drop your files here to be downloaded
            </Trans>
        </div>}
    </form>
}

export default DragNDropFiles;