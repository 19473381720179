import { createAvatar } from '@dicebear/core';
import { avataaars } from '@dicebear/collection';

export const Avatar = () => {
    const avatar = createAvatar(avataaars, {
        "seed": "Gizmo",
      });
      
      const svg = avatar.toDataUriSync();

      return <img src={svg} alt="avatar" width={32} />;
};