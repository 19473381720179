import { useEffect, useState } from "react";

import "./style.css";
import { Trans } from "react-i18next";

const SLIDES = [
  {
    picture: "/images/couple.svg",
    title: "Capture all feelings",
    description:
      "Photos shared by guests, whether they are spontaneous or scheduled moments, are priceless memories that you can cherish and relive again and again. By using WeddiUp, you can be sure not to miss an important and precious moment of this special day.",
  },
  {
    picture: "/images/group.svg",
    title: "All the party in one place",
    description:
      "With WeddiUp, guests can share photos they've taken throughout the wedding party. All the highlights of the day will be captured in real time. You will be able to relive the most important and moving moments of your wedding. This unique feature brings all the photos together in one place, making it easy to access and save unforgettable memories.",
  },
  {
    picture: "/images/app.svg",
    title: "Mobile apps",
    description:
      "WeddiUp is an application available on the most popular mobile platforms, namely Android and iOS. This means that guests will be able to easily download the app to their smartphone and access all the features offered.",
  },
  {
    picture: "/images/download.svg",
    title: "Group all the photos",
    description:
      "With WeddiUp, all the photos taken during the wedding are automatically grouped together in one place, making it easy to find and save the memories of the day. Bride and groom can easily upload all photos with just one click, saving them the hassle of uploading each photo individually.",
  },
];

export const FeatureSlider = () => {
  const [cursor, setCursor] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCursor((cursor + 1) % SLIDES.length);
    }, 10000);
    return () => clearInterval(interval);
  });
  return (
    <div>
      <div className="feature-slider">
        {SLIDES.map((slide, index) => (
          <div
            key={index}
            className={`feature-slide ${index === cursor ? "active" : ""}`}
          >
            <div className="feature-slide__picture">
              <img src={slide.picture} alt={slide.title} />
            </div>
            <div className="feature-slide__content">
              <h2><Trans>{slide.title}</Trans></h2>
              <p><Trans>{slide.description}</Trans></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
