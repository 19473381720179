import "./style.css"

const ProgressBar = (props) => {
    const { completion } = props;



    return (
        completion <= 1 ? <div className="progress-bar" style={{ transform: `scaleX(${completion})` }}>

        </div> : <div className="progress-bar "></div>
    );
};

export default ProgressBar;