import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api, { publicApi } from "../../../api";
import Input from "../../../components/Input";
import { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Logo from "../../../assets/Logo.svg";
import { ToastContext } from "../../../components/Toast";
import { getPasswordNotation } from "../../../helpers/passwordChecker";

import "./style.css";
import { useFetch } from "../../../hooks";
import { FeatureSlider } from "../../../components/FeatureSlider";

const Register = () => {
  const { addToast } = useContext(ToastContext);
  const [cvgValidated, setCgvValidated] = useState(false);

  const { t } = useTranslation();
  const [passwordValue, setPasswordValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [weddingNameValue, setWeddingNameValue] = useState("");

  const { data: offers } = useFetch(() => api.public.offers.list());
  const freeOffer = offers?.find((offer) => offer.type === "FREE");
  const navigate = useNavigate();
  const register = async (evt) => {
    evt.preventDefault();
    const { email, password, weddingName } = evt.target;
    try {
      await api.public.users.create({
        email: email.value,
        password: password.value,
      });
      addToast({
        message: t("Your account has been created"),
        type: "success",
      });
      const loginInfo = await api.public.users.login({
        username: email.value,
        password: password.value,
      });
      publicApi.setToken(loginInfo["access_token"]);
      localStorage.setItem("wu-jwt", loginInfo["access_token"]);
      const profilUser = await api.public.users.profil();

      const wedding = await api.public.wedding.create({
        name: weddingName.value,
        ownerId: profilUser.userId,
      });
      const contract = await api.public.contracts.create({
        offerId: freeOffer.id,
        userId: profilUser.userId,
        weddingId: wedding.id,
      });
      if (contract.paid) {
        addToast({
          message: t("Thanks for order! Enjoy your moment!"),
          type: "success",
        });
        navigate(`/${wedding.id}/dashboard`);
      } else {
        addToast({
          message: t(
            "Thanks for order! You will be redirected to our payment provider"
          ),
          type: "success",
        });
        setTimeout(() => {
          window.location.href = contract.paymentUrl;
        }, 3000);
      }
    } catch (e) {
      addToast({
        message: t("Unable to create your account"),
        type: "error",
      });
    }
  };

  const isFormValid =
    cvgValidated &&
    getPasswordNotation(passwordValue) >= 3 &&
    emailValue &&
    weddingNameValue;

  return (
    <div className="register-container">
      <section className="features">
        <FeatureSlider />
        <a href="#registering" className="go-register-button">
          <Button color="success" variant="contained">
            <Trans>Register</Trans>
          </Button>
        </a>
      </section>
      <section className="register-form" id="registering">
        <form className="form" method="post" onSubmit={register}>
          <span className="logo">
            <img src={Logo} alt="logo weedi up" /> Weddi'UP
          </span>
          <h1>{t("Registration form")}</h1>
          <div className="fields">
            <Input
              required
              isValid={emailValue}
              onChange={setEmailValue}
              type="email"
              name="email"
              label={t("Fill your email")}
              placeholder={t("Ex: johndoe@domain.tld")}
            />
            <Input
              required
              checker
              onChange={setPasswordValue}
              placeholder={t("********")}
              type="password"
              label={t("Fill your new password")}
              name="password"
              isValid={getPasswordNotation(passwordValue) >= 3}
            />
            <Input
              required
              isValid={weddingNameValue}
              onChange={setWeddingNameValue}
              type="text"
              name="weddingName"
              label={t("Create your wedding name")}
              placeholder={t("Ex: John and Jessica")}
            />
          </div>
          <div className="offers">
            <h2>
              <Trans>Start! It's free!</Trans>
            </h2>
            <span>
              <Trans>Start now and keep all your wedding photo secured.</Trans>
            </span>
            <p>
              <div className="offer">
                <Trans>Storage: 10Go</Trans>
              </div>
              <div className="offer-feature">
                <Trans>Without card</Trans>
              </div>
              <div className="offer__important">
                <Trans>+/- 2000 pictures</Trans>
              </div>
              <div className="offer-feature">
                <Trans>10 guests</Trans>
              </div>
            </p>
          </div>
          {/*<div className="offers">
            <label>
              <Trans>Choose an offer</Trans>
            </label>
            {!offerLoading &&
              sortedOffers?.map((item) => (
                <div
                  key={item.id}
                  className={`offer-card ${
                    offer === item.id ? "selected" : ""
                  }`}
                  onClick={() => setOffer(item.id)}
                >
                  {item.type === "FREE" && <Free price={item.amount} />}
                  {item.type === "PREMIUM" && <Premium price={item.amount} />}
                  {item.type === "GOLD" && <Gold price={item.amount} />}
                </div>
              ))}
                </div>*/}
          {passwordValue && getPasswordNotation(passwordValue) < 3 && (
            <div className="password-error">
              <Trans>
                Please, fill a password with at least 8 characters, 1 uppercase,
                1 lowercase and 1 number.
              </Trans>
            </div>
          )}
          <div className="footer-form">
            <div className="cgv-input">
              <input
                type="checkbox"
                onChange={(evt) => setCgvValidated(evt.currentTarget.checked)}
              />
              <Trans>I accept the commercial condition</Trans>
              <a href="/cgv.pdf" target="_blank">
                <Trans>Read the cgv</Trans>
              </a>
            </div>
            <div className="buttons-container">
              <a href="/authentication/login">
                <Trans>Already registered? Go to login page</Trans>
              </a>
              <Button
                disabled={!isFormValid}
                className="register-button"
                type="submit"
                color="success"
                variant="contained"
              >
                <Trans>Create a wedding</Trans>
              </Button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

const priceText = (price) => {
  return `${(price / 100).toFixed(2).replace(".", ",")}€`;
};

const Free = ({ price }) => (
  <>
    <h2>
      <Trans>{!price ? "FREE" : priceText(price)}</Trans>
    </h2>
    <p>
      <div className="offer">
        <Trans>Storage: 10Go</Trans>
      </div>
      <div className="offer-feature">
        <Trans>Without card</Trans>
      </div>
      <div className="offer__important">
        <Trans>+/- 2000 pictures</Trans>
      </div>
      <div className="offer-feature">
        <Trans>10 guests</Trans>
      </div>
    </p>
  </>
);

const Premium = ({ price }) => (
  <>
    <h2>
      {!price ? "PREMIUM" : priceText(price)}{" "}
      <span className="offer-period">
        <Trans>per year</Trans>
      </span>
    </h2>
    <p>
      <div className="offer">
        <Trans>Storage: 100Go</Trans>
      </div>
      <div className="offer__important">
        <Trans>+/- 20,000 pictures</Trans>
      </div>
      <div className="offer-feature">
        <Trans>250 guests</Trans>
      </div>
      <div className="offer-feature">
        <Trans>♾️ album downloads</Trans>
      </div>
    </p>
  </>
);

const Gold = ({ price }) => (
  <>
    <h2>
      {!price ? "GOLD" : priceText(price)}{" "}
      <span className="offer-period">
        <Trans>per year</Trans>
      </span>
    </h2>
    <p>
      <div className="offer">
        <Trans>Storage: 1To</Trans>
      </div>
      <div className="offer__important">
        <Trans>more than 100,000 pictures</Trans>
      </div>
      <div className="offer-feature">
        <Trans>♾️ guests</Trans>
      </div>
      <div className="offer-feature">
        <Trans>♾️ album downloads</Trans>
      </div>
    </p>
  </>
);

export default Register;
