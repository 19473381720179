import { Button, Card, Snackbar, Alert } from "@mui/material"
import api from "../../../api"
import Input from "../../../components/Input";
import { useContext, useState } from "react";
import { ToastContext } from "../../../components/Toast";
import { Trans, useTranslation } from "react-i18next";


const ResetPassword = () => {
    const {t} = useTranslation()
    const {addToast} = useContext(ToastContext);
    const [alert, setAlert] = useState(false)
    const reset = async (evt) => {
        evt.preventDefault()
        const { email } = evt.target
        try {
            await api.public.users.resetPassword(encodeURIComponent(email.value))
            setAlert(true)
            addToast({
                message: t("Watch your email box, an reset password link was sent."),
                type: "success"
            })
        } catch(error) {
            addToast({
                message: t("Unable to reset your password"),
                type: "error"
            })
        }

    }
    return <><form className="form" method="post" onSubmit={reset} >
        <Card className="formContainer">
            <h2 className=""><Trans>Fill your email address</Trans></h2>
            <Input placeholder={t("Fill your email")} label={t("Email")} name="email" />
            <Button type="submit" color="success" variant="contained"><Trans>Validate</Trans></Button>
        </Card>
    </form>
        <div>
            <Snackbar open={alert}>
                <Alert severity="success"><Trans>You will received an email with a reset password link into.</Trans></Alert>
            </Snackbar>
        </div>

    </>

}

export default ResetPassword