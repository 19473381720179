import { Routes, Route, redirect } from "react-router-dom";
import { Grid } from "@mui/material";
import Login from "./Login";
import Logo from "../../assets/Logo.svg"
import Title from "../../components/Title";
import UpdateNewPassword from "./UpdateNewPassword";
import ResetPassword from "./ResetPassword";
import CreatePassword from "./CreatePassword";


const Authentication = () => {

    return <div className="boxContainer">
        <img src={Logo} alt="logo weedi up" />
        <Title />
        <Grid spacing={2} padding={2}>
            <Grid item xs={6}>
                <Routes>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/reset" element={<ResetPassword />}></Route>
                    <Route path="/:userId/update-password" element={<UpdateNewPassword />}></Route>
                    <Route path="/:userId/create-password" element={<CreatePassword />}></Route>
                    <Route path="/" element={<Login />}></Route>
                </Routes>
            </Grid>
        </Grid>
    </div>
}

export default Authentication;