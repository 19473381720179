import api from "../../api";
import { useFetch } from "../../hooks";

import PlaceholderImage from "../../assets/placeholder.png";
import { useEffect } from "react";


const ImageView = ({id, size, ...props}) => {
    const {data, loading} = useFetch(() => api.public.photos.getBlob(id, size), {cached: true, cacheKey: `image_${id}_${size}`})
    return loading ? <img src={PlaceholderImage} className="photo" {...props} /> : <img src={data} className="photo" {...props} />
}

const Image = ({id, size, ...props}) => {
    const {data: image, reload} = useFetch(() => api.public.photos.getById(id));

    return image?.status === "RESIZED" ? <ImageView id={id} size={size} {...props} /> : <img onClick={() => reload()} src={PlaceholderImage} className="photo" {...props} />
}

export default Image;