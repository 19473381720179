
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useState } from 'react';
import Image from '../Image';

const ImageSlider = ({photos, onNext, children}) => {
    const [selectedId, setSelectedId] = useState(null)
    const [currentPhoto, setCurrentPhoto] = useState(photos?.find(photo => photo.id === selectedId))
    const [cursor, setCursor] = useState(photos?.findIndex(photo => photo.id === selectedId))
    const selectPhoto = useCallback(async (id) => {
        setSelectedId(id)
        setCurrentPhoto(photos?.find(photo => photo.id === id))
        const newCursor = photos?.findIndex(photo => photo.id === id)
        if(newCursor + 1 >= photos.length) onNext?.();
        setCursor(newCursor)
    }, [onNext, photos, setCursor, setCurrentPhoto])

    const closeModal = () => {
        selectPhoto(null)
    }

    const next = useCallback(async () => {
        const nextPhoto = photos[cursor + 1]
        if (!nextPhoto) return
        selectPhoto(nextPhoto.id);
    }, [cursor, photos, selectPhoto])

    const previous = () => {
        const previousPhoto = photos[cursor - 1]
        if (!previousPhoto) return
        selectPhoto(previousPhoto.id);

    }

    const thumbnailList = photos?.filter((file, index) => index - cursor < 4 && index - cursor > -4)

    return <>
    {children({selectPhoto})}
    {currentPhoto && <>
        <div onClick={() => selectPhoto(null)} className="overlay"></div>
        <div className="modal">
            <CloseIcon className="modal-close filter-on-focus" onClick={closeModal} />
            <KeyboardArrowLeftIcon className={`arrow-left filter-on-focus ${cursor - 1 < 0 ? "arrow-disable" : ""}`} onClick={previous} fontSize="large" />

            <KeyboardArrowRightIcon className={`arrow-right filter-on-focus ${cursor + 1 >= photos.length ? "arrow-disable" : ""}`} onClick={next} fontSize="large" />
            <div className="slider">
                <Image size="1024x" id={currentPhoto.id} key={currentPhoto.id} className="modal-img" />

            </div>
            <div className="mini-slider">
                {
                    thumbnailList.map((p, key) => <div className="mini-photo-container" key={key} onClick={() => selectPhoto(p.id)} >
                        {p.key && <Image size="256x" id={p.id} className={`mini-photo ${currentPhoto?.id === p.id ? "focus-selected-photo" : "photo-filter"}`} key={`thumbnail-${p.id}`} item xs={1} />}
                    </div>)

                }
            </div>
            {
                <div className="photo-counter" > {cursor + 1}/{photos.length}</div>
            }
        </div>
    </>}
    
</>
}

export default ImageSlider;
