import { Grid, Slider } from "@mui/material";

import "./styles.css";
import { Add, FavoriteBorder } from "@mui/icons-material";
import api from "../../../api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useUploader } from "../../../contexts/Upload";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { CheckCircleOutline, Cancel } from "@mui/icons-material";
import ProgressBar from "../../../components/ProgressBar";
import DeleteIcon from "@mui/icons-material/Delete";

import Heart from "@mui/icons-material/Favorite";
import Lock from "@mui/icons-material/Lock";
import Image from "../../../components/Image";
import { useTranslation } from "react-i18next";
import ImageSlider from "../../../components/ImageSlider";
import Pagination from "../../../components/Pagination";

const NB_PHOTO_PER_PAGE = 19;

const PhotoList = () => {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const { addFiles, files, progression } = useUploader();
  const { weddingId } = useParams();
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(null);
  const scrollRef = useRef();

  const sendPhoto = async (evt) => {
    const filesToUpload = evt.target.files;
    addFiles(weddingId, filesToUpload);
  };

  const loadPhotos = async (page) => {
    setLoading(true);
    const loadedPhotos = await api.public.photos.list(
      weddingId,
      page,
      NB_PHOTO_PER_PAGE
    );
    setCount(loadedPhotos.count);
    const photoList = [...loadedPhotos?.medias];
    setPhotos(photoList);
    setLoading(false);
  };

  const loadNextPage = async () => {
    const nextPage = page + 1;
    setPage(nextPage);
    await loadPhotos(nextPage);
  };

  const changePage = async (event, page) => {
    console.log(page);
    const nextPage = page;
    setPage(nextPage);
    await loadPhotos(nextPage);
  };

  const publish = async (id) => {
    await api.public.photos.publish(id);
    loadPhotos(page);
  };

  const deletePhoto = async (id) => {
    await api.public.photos.delete(id);
    loadPhotos(page);
  };

  const addEmotion = async (photo) => {
    const profile = await api.public.users.profil()
    const user = await api.public.users.getUser(profile?.userId)
    const impression = photo.Impression.find(impr => impr?.user.id === user.id)
    if (!impression) {
      await api.public.photos.addImpression({
        id: photo.id,
        userId: user.id,
        emotion: "LIKE",
        mediaId: photo.id,
      });
    } else {
      await api.public.photos.deleteImpression({
        id: photo.id,
        impressionId: impression.id,
      });
    }

    loadPhotos(page);
  }

  const loadUser = async () => {
    const profile = await api.public.users.profil()
    const user = await api.public.users.getUser(profile?.userId)
    setUser(user);
  }

  const hasAlreadyLiked = (photo) => {
    const impression = photo.Impression.find(impr => impr?.user.id === user?.id)
    return impression
  }

  const complement = NB_PHOTO_PER_PAGE - photos.length;
  useEffect(() => {
    loadPhotos(1);
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div ref={scrollRef}>
      <Grid padding={2} container className="PhotosContainer">
        <div className="PhotoItem Download" item xs={1}>
          <Add width={64} fontSize="large" />
          <input type="file" onChange={sendPhoto} multiple="multiple" />
        </div>
        <ImageSlider photos={photos} onNext={loadNextPage}>
          {({ selectPhoto }) =>
            photos.map((p, key) => (
              <div
                onClick={() => selectPhoto(p.id)}
                key={`picture_${p.id}`}
                className="PhotoItem"
                item
                xs={1}
              >
                {p.key && <Image size="256x" id={p.id} />}
                <div className="ItemActionsContainer">
                  {!p.published && (
                    <span
                      className="ItemAction ItemAction--publish"
                      onClick={(evt) =>
                        evt.preventDefault() ||
                        evt.stopPropagation() ||
                        publish(p.id)
                      }
                    >
                      <Lock className="Icon" />
                    </span>
                  )}
                  <span
                    className="ItemAction ItemAction--publish ItemAction--delete"
                    onClick={(evt) =>
                      evt.preventDefault() ||
                      evt.stopPropagation() ||
                      deletePhoto(p.id)
                    }
                  >
                    <DeleteIcon className="Icon" />
                  </span>
                </div>

                <div className="Emotions">
                    <Heart fontSize="inherit" onClick={(evt) => {
                        evt.preventDefault()
                        evt.stopPropagation()
                        addEmotion(p)
                    }} className={`Favorite ${hasAlreadyLiked(p) ? "Favorite-liked" : ""}`} />
                    {
                      p?._count?.Impression
                    }
                  </div>
              </div>
            ))
          }
        </ImageSlider>
        {[...new Array(complement)].map((_, key) => (
          <div
            key={`picture_${key}`}
            className="PhotoItem PhotoItem--hidden"
            item
            xs={1}
          ></div>
        ))}
      </Grid>

      <Pagination max={count} perPage={19} handlePageChange={changePage} />
      <div className="Queue">
        {files.length > 0 && <ProgressBar completion={progression} />}
        {files?.map((file) => (
          <div className="queue-item">
            <div className="file-name-container">{file.name}</div>
            {file.status === "queued" && (
              <div className="icon-box-status file-download-icon">
                <DownloadingIcon fontSize="small" />
              </div>
            )}
            {file.status === "inprogress" && (
              <div className="icon-box-status  file-download-icon">
                <DownloadingIcon fontSize="small" />
              </div>
            )}
            {file.status === "success" && (
              <div className="icon-box-status  check-circle-outline">
                {" "}
                <CheckCircleOutline fontSize="small" />{" "}
              </div>
            )}
            {file.status === "error" && (
              <div>
                {" "}
                <Cancel
                  className="icon-box-status cancel"
                  color="error"
                  fontSize="small"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoList;
