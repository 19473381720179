class CustomError extends Error {
    set(message, name) {
        this.message = message;
        this.name = name;
    }
}

export class BadRequest extends CustomError { }
export class InternalError extends CustomError { }
export class UnAuthorizeError extends CustomError { }

class HttpError {
    static 400 = new BadRequest()
    static 500 = new InternalError()
    static 401 = new UnAuthorizeError()
}

class Client {
    constructor({ baseUrl }) {
        this.baseUrl = baseUrl;
        this.token = localStorage.getItem("wu-jwt");
        this.clearToken = this.clearToken.bind(this);
    }
    setToken(value) {
        localStorage.setItem("wu-jwt", value);
        this.token = value;
    }

    getToken() {
        return this.token || localStorage.getItem("wu-jwt");
    }

    clearToken() {
        localStorage.clear();
        this.token = null;
    }
}

class ApiModule {
    constructor(client) {
        this.client = client;
    }
    async request(req) {
        const JSONResponse = await req
        if (HttpError[JSONResponse.status]) {
            const JSONError = await JSONResponse.json()
            const error = HttpError[JSONResponse.status];
            error.set(JSONError.message, JSONError.error)
            throw error;
        }
        const response = await JSONResponse.json()
        return response;
    }

    async requestBlob(req) {
        const JSONResponse = await req
        if (HttpError[JSONResponse.status]) {
            const JSONError = await JSONResponse.json()
            const error = HttpError[JSONResponse.status];
            error.set(JSONError.message, JSONError.error)
            throw error;
        }
        const response = await JSONResponse.blob()
        return response;
    }

    postData(data) {
        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }
        if (this.client.getToken()) {
            config.headers['Authorization'] = `Bearer ${this.client.token}`
        }
        return config;
    }

    patchData(data) {
        const config = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }
        if (this.client.getToken()) {
            config.headers['Authorization'] = `Bearer ${this.client.token}`
        }
        return config;
    }

    uploadData(data) {
        const config = {
            method: "POST",
            body: data,
            headers: {
            },
        }

        if (this.client.getToken()) {
            config.headers['Authorization'] = `Bearer ${this.client.token}`
        }
        return config;
    }

    getData(data) {
        const config = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },

        }
        if (this.client.getToken()) {
            config.headers['Authorization'] = `Bearer ${this.client.token}`
        }
        return config;
    }

    deleteData(data) {
        const config = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },

        }
        if (this.client.getToken()) {
            config.headers['Authorization'] = `Bearer ${this.client.token}`
        }
        return config;
    }
}

class WeddingApi extends ApiModule {
    async get(id) {
        return this.request(fetch(`${this.client.baseUrl}/weddings/${id}`, this.getData({})))
    }

    async update(Id, data) {
        return this.request(fetch(`${this.client.baseUrl}/weddings/${Id}`, this.patchData(data)));
    }

    async create(data) {
        return this.request(fetch(`${this.client.baseUrl}/weddings`, this.postData(data)));
    }

    async listInvitations(id) {
        return this.request(fetch(`${this.client.baseUrl}/weddings/${id}/invitations`, this.getData({})))
    }

    async createInvitation(id, data) {
        return this.request(fetch(`${this.client.baseUrl}/weddings/${id}/invitations`, this.postData(data)));
    }
    async getStats(id) {
        return this.request(fetch(`${this.client.baseUrl}/statistics?wedding_id=${id}`, this.getData({})))
    }
}

class UserApi extends ApiModule {
    async get(id) {
        return this.request(fetch(`${this.client.baseUrl}/users/${id}`, this.getData()))
    }

    async create(data) {
        return this.request(fetch(`${this.client.baseUrl}/users`, this.postData(data)));
    }

    async update(userId, data) {
        return this.request(fetch(`${this.client.baseUrl}/users/${userId}`, this.patchData(data)));
    }

    async login(data) {
        return this.request(fetch(`${this.client.baseUrl}/auth/login`, this.postData(data)));
    }

    async profil() {
        return this.request(fetch(`${this.client.baseUrl}/auth/profile`, this.getData()));
    }

    async getUser(id) {
        return this.request(fetch(`${this.client.baseUrl}/users/${id}`, this.getData()));
    }

    async updatePassword(userId, data) {
        return this.request(fetch(`${this.client.baseUrl}/users/${userId}/renew-password`, this.postData(data)));
    }

    async resetPassword(email) {
        return this.request(fetch(`${this.client.baseUrl}/users/ask-for-renew-password?email=${email}`, this.postData({})));
    }
}

class ContractApi extends ApiModule {
    async create(data) {
        return this.request(fetch(`${this.client.baseUrl}/contracts`, this.postData(data)));
    }
    async getPaymentStatus(contractId, data) {
        return this.request(fetch(`${this.client.baseUrl}/contracts/${contractId}/payment`, this.getData(data)));
    }
}

class PhotoApi extends ApiModule {
    async upload(weddingId, data) {
        return this.request(fetch(`${this.client.baseUrl}/medias/${weddingId}`, this.uploadData(data)));
    }

    async list(data, page = 1, perPage = 19) {
        return this.request(fetch(`${this.client.baseUrl}/medias?page=${page}&per_page=${perPage}&weddingId=${data}`, this.getData(data)));
    }

    async getById(id) {
        return this.request(fetch(`${this.client.baseUrl}/medias/${id}`, this.getData({})));
    }

    async getBlob(id, size) {
        const blob = await this.requestBlob(fetch(`${this.client.baseUrl}/medias/${id}/raw?size=${size}`, this.getData({})));
        const base64 = await new Promise((resolve, _) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsDataURL(blob);
        })
        return base64;
    }

    async publish(id) {
        return this.request(fetch(`${this.client.baseUrl}/medias/${id}`, this.patchData({
            published: true,
            id,
        })));
    }

    async delete(id) {
        return this.request(fetch(`${this.client.baseUrl}/medias/${id}`, this.deleteData({})));
    }

    async addImpression(data) {
        return this.request(fetch(`${this.client.baseUrl}/medias/${data.id}/impressions`, this.postData(data)));
    }

    async deleteImpression(data) {
        return this.request(fetch(`${this.client.baseUrl}/medias/${data.id}/impressions/${data.impressionId}`, this.deleteData(data)));
    }
}

class BulkApi extends ApiModule {
    async create(weddingId) {
        return this.request(fetch(`${this.client.baseUrl}/bulks/${weddingId}`, this.postData({})));
    }

    async list(data) {
        return this.request(fetch(`${this.client.baseUrl}/bulks?weddingId=${data}`, this.getData(data)));
    }
}

class OfferApi extends ApiModule {

    async list(data) {
        return this.request(fetch(`${this.client.baseUrl}/offers`, this.getData(data)));
    }
}

export const publicApi = new Client({ baseUrl: process.env.REACT_APP_API_URL || "http://localhost:3000" })

const api = {
    public: {
        ...publicApi,
        wedding: new WeddingApi(publicApi),
        users: new UserApi(publicApi),
        contracts: new ContractApi(publicApi),
        photos: new PhotoApi(publicApi),
        bulks: new BulkApi(publicApi),
        offers: new OfferApi(publicApi),
    }
}

export default api;