import { useEffect, useState } from "react"
import api from "../../../api"
import { useParams } from "react-router-dom"
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import DownloadingIcon from '@mui/icons-material/Downloading';
import moment from 'moment';

import "./style.css";
import { Trans } from "react-i18next";
import { useFetch } from "../../../hooks";
import getOfferFromWedding from "../../../helpers/getOffer";


const DownloadList = () => {
    const {data: bulks, reload} = useFetch(() => api.public.bulks.list(weddingId))
    const { weddingId } = useParams()

    const {data: wedding} = useFetch(() => api.public.wedding.get(weddingId));

    const create = async () => {
        await api.public.bulks.create(weddingId)
        reload()
    }

    const offer = getOfferFromWedding(wedding)

    return <div className="table">
        <div className="bulks-heading-container">
            <div>

                <div className="create-bulk-btn">
                    <Button disabled={offer?.maxBulks !== -1 && offer?.maxBulks <= bulks?.length} className="create-bulks" onClick={create} variant="contained" color="secondary">
                        <span><Trans>Create a paquet for download</Trans></span>
                        <Download />
                    </Button>
                </div>
            </div>
            {
                bulks?.length === 0 && <div className="alert-message">
                    <span className="message-filter"><Trans>No any paquet created</Trans></span>
                </div>
            }
        </div>
        <div className="bulks">
            {
                bulks?.map(
                    bulk => <div className="bulk" key={bulk.id}>
                        <div className="bulk-item">
                            <div >
                                <div className="bulk-cell">
                                    <div className="update-icon"><UpdateIcon /></div>
                                    <span className="item-centered">{`${moment(new Date(bulk?.createdDate)).format('DD/MM/YYYY')}`}</span>
                                </div>
                            </div>
                            <div>
                                <div className="bulk-cell">
                                    <div className="update-disable-icon"><UpdateDisabledIcon /> </div>
                                    <span className="item-centered"> {`${bulk?.stopDate ? moment(new Date(bulk?.stopDate)).format('DD/MM/YYYY') : '--'}`}</span>
                                </div>
                            </div>
                            <div >
                                {
                                    bulk.status === "DONE" && <div className="download-cell"> <span className="download-label item-centered"><Trans>Download your paquet here:</Trans></span>
                                        <a href={bulk.preSignedUrl} target="_blank" rel="noreferrer">
                                            <div className="download-for-offline"> <DownloadForOfflineRoundedIcon fontSize="large" /></div>
                                        </a>
                                    </div>
                                }
                                {
                                    bulk.status === "SCHEDULDED" && <div className="download-label item-centered">
                                        <span><Trans>Paquet is under creation</Trans></span>
                                        <div className="downloading-icon"> <DownloadingIcon fontSize="large" /> </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    </div>
}

export default DownloadList