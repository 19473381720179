import { Routes, Route } from "react-router-dom";
import WeddingName from "./WeedingName";
import { Grid } from "@mui/material";
import ChooseOffer from "./ChooseOffer";
import Logo from "../../assets/Logo.svg"
import Title from "../../components/Title";
import ConfirmPayment from "./ConfirmPayment";


const Create = () => {

    return <div className="boxContainer">
        <img src={Logo} alt="logo weedi up" />
        <Title />
        <Grid spacing={2} padding={2}>
            <Grid item xs={6}>
                <Routes>
                    <Route path="/name" element={<WeddingName />}></Route>
                    <Route path="/:id/choose-offer" element={<ChooseOffer />}></Route>
                    <Route path="/contracts/:contractId/paid" element={<ConfirmPayment />}></Route>
                </Routes>
            </Grid>
        </Grid>
    </div>
}

export default Create;