import { Button, Card } from "@mui/material"

import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import api from "../../../api"
import Input from "../../../components/Input";
import Error from "../../../components/Error";
import { useContext, useState } from "react";
import { ToastContext } from "../../../components/Toast";
import { Trans, useTranslation } from "react-i18next";
import { getPasswordNotation } from "../../../helpers/passwordChecker";

const UpdateNewPassword = () => {
    const {t} = useTranslation()
    const {addToast} = useContext(ToastContext)
    const [passwordValue, setPasswordValue] = useState("")
    const navigate = useNavigate()
    const { userId } = useParams()
    let [searchParams] = useSearchParams();
    const changePassword = async (evt) => {
        evt.preventDefault()
        const { password } = evt.target
        try {
            await api.public.users.updatePassword(userId, { password: password.value, activationCode: Number(searchParams.get("activationCode")) })
            navigate("/authentication/login")
            addToast({
                message: "Your password has been updated",
                type: "success"
            })
        } catch (error) {
            addToast({
                message: "Unable to reset your password",
                type: "success"
            })
        }
    }


    return <form className="form" method="post" onSubmit={changePassword} >
        <Card className="formContainer">
            <h2 className=""><Trans>Change your password</Trans></h2>
            <Input checker onChange={setPasswordValue} placeholder={t("Fill your new password")} type="password" label={t("Fill your new password")} name="password" />
            {
                getPasswordNotation(passwordValue) < 3 && (<div className="PasswordError">
                    <Trans>
                        Please, fill a password with at least 8 characters, 1 uppercase, 1 lowercase and 1 number.
                    </Trans>
                </div>)
            }
            <Button type="submit" color="success" variant="contained"><Trans>Validate</Trans></Button>
        </Card>
    </form>
}

export default UpdateNewPassword