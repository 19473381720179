import { useEffect, useState } from "react";
import Title from "../../components/Title";
import Logo from "../../assets/Logo.svg"
import api from "../../api";

import './style.css'
import { Link } from "react-router-dom";
import { useFetch } from "../../hooks";

const loadInvitations = async () => {
    const user = await api.public.users.profil()
    const userDetails = await api.public.users.getUser(user.userId);
    return {invitations: userDetails.invitations, wedding: userDetails.Wedding}
}

const Invitations = () => {
    const { data} = useFetch(() => loadInvitations())

    return <form className="form" method="post">
        <img src={Logo} alt="logo weediup" />
        <Title />
        <div className="invitationContainer">
            {
                data?.wedding && <Link to={`/${data?.wedding?.id}/dashboard/photos`} >
                <div className={data?.wedding.name ? "myWedding" : "invitationCard"}>
                    <h2 className="wedding-item">{data?.wedding?.name}</h2>
                </div>
            </Link>
            }
            {
                data?.invitations?.map(invitation => <Link to={`/${invitation?.wedding?.id}/dashboard/photos`} >
                    <div className={invitation.name ? "myWedding" : "invitationCard"}>
                        <h2 className="wedding-item">{invitation?.wedding?.name || invitation?.name}</h2>
                    </div>
                </Link>)
            }
        </div>
    </form>
}

export default Invitations;