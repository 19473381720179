import { Route, Routes } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import './App.css';
import Create from './pages/CreateWedding';
import Authentication from './pages/Authentication';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Dashboard from './pages/Dashboard';
import Invitations from './pages/Invitations';
import Home from './pages/Home';
import UserProfile from './pages/UserProfile';
import Register from './pages/Authentication/Register';

Sentry.init({
  dsn: "https://fa3407576d024c349aa797e951805d26@o4505464516247552.ingest.sentry.io/4505464518475776",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/weddiup\.io\//],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    success: {
      main: "#EF233C"
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#2B2D42',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create/*" element={<Create />} />
        <Route path="/authentication/register" element={<Register />} />
        <Route path="/authentication/*" element={<Authentication />} />
        <Route path="/:weddingId/dashboard/*" element={<Dashboard />} />
        <Route path="/profile" element={<UserProfile />}></Route>
        <Route path="/invitations" element={<Invitations />} />
        <Route path="/:lang/">
          <Route path="create/*" element={<Create />} />
          <Route path="authentication/register" element={<Register />} />
          <Route path="authentication/*" element={<Authentication />} />
          <Route path=":weddingId/dashboard/*" element={<Dashboard />} />
          <Route path="profile" element={<UserProfile />}></Route>
          <Route path="invitations" element={<Invitations />} />
        </Route>
      </Routes>
    </ThemeProvider>

  );
}

export default App;
