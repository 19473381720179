import { Cancel, Check, CheckCircleOutline, Close, Delete, Mail, PlusOne } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import api from "../../../api";
import { useParams } from "react-router-dom";

import "./style.css";
import { Trans } from "react-i18next";
import { ToastContext } from "../../../components/Toast";
import { useFetch } from "../../../hooks";
import getOfferFromWedding from "../../../helpers/getOffer";


const GuestList = () => {
    const {addToast} = useContext(ToastContext)
    const { weddingId } = useParams()
    const [invitations, setInvitations] = useState([])
    const [displayForm, setDisplayForm] = useState(false)
    const {data: wedding} = useFetch(() => api.public.wedding.get(weddingId));
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const loadInvitations = useCallback(async () => {
        setInvitations(await api.public.wedding.listInvitations(weddingId))
    }, [weddingId])

    const createInvitation = useCallback(async (evt) => {
        evt.preventDefault()
        await api.public.wedding.createInvitation(weddingId, {
            email,
            name,
        })
        loadInvitations()
        setDisplayForm(false)
        setEmail("")
        setName("")
        addToast({
            type: "success",
            message: "Invitation sent successfully"
        })
    }, [email, loadInvitations, name, weddingId]) 

    const offer = getOfferFromWedding(wedding)

    useEffect(() => {
        loadInvitations(weddingId)
    }, [])

    return <div onSubmit={createInvitation}>
        {displayForm && <>
            <div className="shadow" onClick={() => setDisplayForm(false)}></div>
            <div className="AddForm">
                <h2><Trans>Add a guest</Trans></h2>
                <input name="name" placeholder="Christelle et Marc" value={name} onChange={(evt) => setName(evt?.currentTarget?.value)}/>
                <input name="email" placeholder="Email de l'invité" value={email} onChange={(evt) => setEmail(evt?.currentTarget?.value)}/>
                <div className="formActions">
                    <Button onClick={() => setDisplayForm(false)} variant="outlined" endIcon={<Close />} color="error" >
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button onClick={createInvitation} type="submit" variant="contained" endIcon={<Check />} color="secondary" >
                        <Trans>Send invitation</Trans>
                    </Button>
                </div>

            </div>
        </>
        }

        <div className="table">
            <div className="add-guest">
                <div>

                    <div style={{ textAlign: 'right' }}>
                        <Button disabled={offer?.maxGuests <= invitations?.length} className="add-guest" onClick={() => setDisplayForm(true)} variant="contained" endIcon={<PlusOne />} color="secondary" >
                            <Trans>Add a guest</Trans>
                        </Button>
                    </div>
                </div>
                {invitations?.length === 0 && <div>
                    <div colSpan={4} className="alert-message"><span className="message-filter"><Trans>No any guest added</Trans></span></div>
                </div>
                }
            </div>
            <div className="guest-list">
                {
                    invitations?.map(invitation => <div className="row-guest" key={invitation.key}>
                        <div className="user-active">
                            {
                                invitation.user.active ? <CheckCircleOutline color="secondary" /> : <Cancel color="error" />
                            }
                        </div>
                        <div>
                            <div className="GuestValue">
                                {
                                    invitation.name
                                }
                            </div>
                        </div>
                        <div>
                            <div className="GuestValue">
                                {
                                    invitation.user.email
                                }
                            </div>

                        </div>
                        {/*<div className="centered">
                            <Button className="border-onfocus" variant="outlined" endIcon={<Mail />} color="secondary" >
                                <Trans>Re-send invitation email</Trans>
                            </Button>
                            <Button className="border-onfocus" variant="outlined" endIcon={<Delete />} color="error" >
                                <Trans>Remove the invitation</Trans>
                            </Button>
                        </div>*/}
                    </div>)
                }
            </div>
        </div>
    </div>
}

export default GuestList;