import "./style.css"
import { Routes, Route, NavLink, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PhotoList from "./PhotoList";
import { useEffect, useState } from "react";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import api from "../../api";
import { Download, Logout, PeopleAlt, Person3, Settings } from "@mui/icons-material";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import GuestList from "./GuestList";
import DownloadList from "./Download";
import { Trans, useTranslation } from "react-i18next";
import DragNDropFiles from "../../components/DragNDropFiles";
import Header from "../../container/Header";
import isOwner from "../../helpers/isOwner";


const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const { weddingId } = useParams()
    const [user, setUser] = useState(null);
    const [error, setError] = useState()
    const { t } = useTranslation();
    const getUser = async () => {
        const profil = await api.public.users.profil()
        const user = await api.public.users.get(profil.userId)
        if (!user) {
            setError('Unable to find the user')
            setLoading(false)
            return
        }
        setUser(user)
        setLoading(false)
        return

    }
    useEffect(() => {
        getUser()
    }, [])


    return error ? error : loading ? "chargement" : <DragNDropFiles weddingId={weddingId}><div className="DashboardContainer">
        
            <Header onDashboardPage />
            {isOwner(user, weddingId) && <div className="fixed-menu" >
                        <nav>
                            <NavLink to={`/${weddingId}/dashboard/photos`}><InsertPhotoIcon /><span className="menu-text">{t("Photo list")}</span></NavLink>
                            <NavLink to={`/${weddingId}/dashboard/guests`}><PeopleAlt /><span className="menu-text">{t("Guests")}</span></NavLink>
                            <NavLink to={`/${weddingId}/dashboard/downloads`}><Download /><span className="menu-text">{t("Downloads")}</span></NavLink>
                        </nav>
            </div>}
            <Grid >
                <Grid container>
                    <Grid item xs={12}>
                        <Routes>
                            <Route path="/photos" element={<PhotoList />}></Route>
                            {isOwner(user, weddingId) && <Route path="/guests" element={<GuestList />}></Route>}
                            {isOwner(user, weddingId) && <Route path="/downloads" element={<DownloadList />}></Route>}
                            {isOwner(user, weddingId) && <Route path="/settings" element={<PhotoList />}></Route>}
                            <Route path="/" element={<PhotoList />}></Route>
                        </Routes>
                    </Grid>
                </Grid>
            </Grid>
        
    </div>
        </DragNDropFiles>
}

export default Dashboard;