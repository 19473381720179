import "./style.css";
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";

import { useEffect, useState } from "react";

import Logo from "../../assets/Logo_large.svg";
import api from "../../api";
import { Person3 } from "@mui/icons-material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useFetch } from "../../hooks";
import { Trans } from "react-i18next";
import getOfferFromWedding from "../../helpers/getOffer";
import { Button } from "@mui/material";
import { Avatar } from "../../components/Avatar";

const logo32 = require("../../assets/logox32.png");

const loadWeddingName = async () => {
  const profile = await api.public.users.profil();
  const user = await api.public.users.getUser(profile.userId);
  const wedding = await api.public.wedding.get(user?.Wedding?.id);
  console.info(wedding);
  return { user, wedding };
};

const Header = (props) => {
  const { data, loading } = useFetch(loadWeddingName);
  const navigate = useNavigate();

  const profile = () => {
    navigate("/profile");
  };

  const invitationCount = data?.user?.invitations?.length;

  const offer = getOfferFromWedding(data?.wedding);

  return (
    <div className="header-container">
      {offer?.type === "FREE" && (
        <div className="offer-container">
        </div>
      )}
      {props.onProfilPage && (
        <div className="logo-container">
          <img src={Logo} alt="Logo Weddi Up" className="header-logo" />
        </div>
      )}

      {props.onDashboardPage && (
        <div className="logo-container">
          <img
            src={logo32}
            alt="Logo Weddi Up"
            className="header-logo logo-on-dashboard"
          />
          <span className="wedding-name">{data?.user?.Wedding?.name}</span>
        </div>
      )}

      <nav className="navigation">
        {data?.user?.Wedding && (
          <NavLink
            to={`/${data?.user?.Wedding?.id}/dashboard/photos`}
            className="dashboard-item"
          >
            <Trans>My wedding</Trans>
          </NavLink>
        )}
        {!!invitationCount && (
          <NavLink className="dashboard-item" to="/invitations">
            <Trans i18nKey={"invitations_link"} count={invitationCount}>
              My invitations {invitationCount}
            </Trans>
          </NavLink>
        )}
      </nav>
      <div className="user-nav">
        <div className="profile-container">
          <span className="username">
            <div onClick={profile} className="header-avatar">
              <Avatar />
            </div>

          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
