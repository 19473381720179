import { useState } from "react";

import "./style.css";

const Stepper = ({onChange, number = 1, start = 1, mutable = true}) => {
    const [step, setStep] = useState(start);
    const steps = [...new Array(number)];
    const change = v => {
        mutable && setStep(v);
        onChange(v);
    }
    return <div className="Stepper">
        {steps.map(
            (st, idx) => <div key={idx} className={`Step ${idx + 1 === step? "active" : "inactive"}`} onClick={() =>change(idx + 1) }>{idx + 1}</div>
        )}
    </div>
}

export default Stepper;