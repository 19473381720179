import Logo from "../../assets/Logo.svg"
import HomeIllustration from "../../assets/Home.svg"
import Title from "../../components/Title";

import './style.css'
import { Link } from "react-router-dom";
import api from "../../api";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const Home = () => {
    const {t} = useTranslation();
    const [user, setUser] = useState(null);
    const getUser = async () => {
        try {
            const user = await api.public.users.profil()
            setUser(user)
            return
        } catch(err) {
            console.error(err) // catch this error to not throw Sentry
        }
    }
    useEffect(() => {
        getUser()
    }, [])
    return <div className="home">
        <img className="logo" src={Logo} alt={t("weddiup logo")} />
        <Title />
        <i><Trans>Enjoy the wedding with pictures</Trans></i>
        <img className="illustration" src={HomeIllustration} alt={t("Illustration of people in love")} />
        <Link className="Button" to={user ? "/invitations" : "/authentication/login"}>
            {user ? t('Join a wedding') : t('Create a wedding')}
        </Link>
        <Link className="Button Button--secondary" to={"/authentication/login"}>
            <Trans>I got an invitation</Trans>
        </Link>
    </div>
}

export default Home;