import { createContext, useContext, useEffect, useState, useCallback } from "react";
import api from "../../api";

export const UploadContext = createContext({})

export const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"]

const STATUS = {
    QUEUED: "queued",
    INPROGRESS: "inprogress",
    ERROR: "error",
    SUCCESS: "success",
}

const MAX_FILE_IN_PROGRESS = 10;

let tmpFiles = []

let fileList = []

const upload = async (fileObject) => {
    const formData = new FormData()
    formData.append("media", fileObject.file);
    const photo = await api.public.photos.upload(fileObject.weddingId, formData);
    return photo;
}

const unQueue = async (onSuccess, onError) => {
    let cursor = 0;
    fileList = fileList.map((file, index) => {
        if(file.status === STATUS.QUEUED && cursor <= MAX_FILE_IN_PROGRESS) {
            cursor += 1;
            file.status = STATUS.INPROGRESS
        }
        return file
    })
    await Promise.all(fileList.map(async (file) => {
        if(file.status !== STATUS.INPROGRESS) return
        try {
            file.query = upload(file)
            file.result = await file.query
            file.status = STATUS.SUCCESS
            onSuccess(fileList)
        } catch (err) {
            file.error = err
            file.status = STATUS.ERROR
            onError(fileList)
        }
    }))
    if(fileList.some(file => file.status === STATUS.QUEUED)) {
        unQueue(onSuccess, onError)
    }
}

export const UploadProvider = ({ children }) => {
    const [files, setFiles] = useState([])

    const addFiles = (weddingId, filesToUpload) => {
        for (const file of filesToUpload) {
            tmpFiles = [...tmpFiles, {
                weddingId,
                name: file.name,
                file,
                status: STATUS.QUEUED,
            }]
            fileList = [...tmpFiles]
            setFiles(fileList)
        }
        unQueue((fileList) => {
            const json = JSON.stringify(fileList)
            const newFileList = JSON.parse(json);
            if (newFileList.some(file => [STATUS.QUEUED, STATUS.INPROGRESS, STATUS.ERROR].includes(file.status))) {
                setFiles(JSON.parse(json))
            } else {
                setFiles([])
            }
        })
    }

    const queue = files?.filter((file) => file.status === STATUS.QUEUED)
    const done = files?.filter(file => file.status === STATUS.SUCCESS || file.status === STATUS.ERROR)
    const progression = files.length === 0 ? 0 : done.length / files.length



    return <UploadContext.Provider value={{ addFiles, queue, files, done, progression }}>{children}</UploadContext.Provider>
}


export const useUploader = () => useContext(UploadContext);