import { createContext, useCallback, useContext, useState } from "react"
import "./style.css"

export const ToastContext = createContext({})

export const ToastProvider = ({children}) => {
    const [toasts, setToasts] = useState([])

    const addToast = ({message, type}) => {
        const newToast = {
            message,
            type,
            hidden: false,
            hide() {
                setTimeout(() => this.hidden = true, 3000)
            }
        }
        newToast.hide();
        const toastList = [...toasts, newToast]
        setToasts(toastList)
    }


    return <ToastContext.Provider value={{addToast, toasts}}>
        {children}
    </ToastContext.Provider>
}

const Toast = ({message, type = "success", hidden}) => {
    return !hidden && <div className={`Toast Toast--${type}`}>{message}</div>
}

export const ToastContainer = () => {
    const {toasts} = useContext(ToastContext)

    return <div className="ToastContainer">
        {toasts?.map((toast, key) => <Toast key={key} message={toast.message} type={toast.type} hidden={toast.hidden} />)}
    </div>
}

