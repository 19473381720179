import { Link, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks";
import api from "../../../api";
import { Trans } from "react-i18next";

const ConfirmPayment = () => {
    const {contractId} = useParams()
    const {data: checkedContract = {}, reload} = useFetch(() => api.public.contracts.getPaymentStatus(contractId))

    return <div>
        {checkedContract?.paid && <p>
            <span><Trans>Your payment is validated. Click on the link below to continue.</Trans></span>
            <Link to={`/${checkedContract.weddingId}/dashboard`}><Trans>My dashboard</Trans></Link> 
        </p>}
        {!checkedContract?.paid && <p>
            <span><Trans>We are waiting for your payment</Trans></span>
            <a href="#" onClick={() => document.location.reload()}><Trans>Reload this page</Trans></a>
        </p>}
    </div>
}

export default ConfirmPayment;