import "./style.css"
import Header from "../../container/Header"
import { useFetch } from "../../hooks"
import api from "../../api";
import InlineEditor from "../../components/InlineEdit"

import { Trans } from "react-i18next";
import ProgressBar from "../../components/ProgressBar"
import getOfferFromWedding from "../../helpers/getOffer"
import formatStorage from "../../helpers/formatStorage"
import isOwner from "../../helpers/isOwner"



const loadWedding = async () => {
    const profile = await api.public.users.profil()
    const user = await api.public.users.getUser(profile?.userId)
    const wedding = user.Wedding ? await api.public.wedding.get(user.Wedding?.id) : {}
    
    return { user, wedding }
}

const loadStats = async (weddingId) => {
    const stats = weddingId ? await api.public.wedding.getStats(weddingId) : {}
    return stats;
}

const UserProfile = (props) => {
    const { data = {}, loading, reload } = useFetch(loadWedding)
    const {data: stats = {}, loading: loadingStats, reload: reloadStats, error: errorStats} = useFetch(loadStats(data?.wedding?.id))
    const { user, wedding } = data || {}

    const updateUserName = async (value) => {
        await api.public.users.update(user.id, { name: value })
        reload()
    }

    const updateUserEmail = async (value) => {
        await api.public.users.update(user.id, { email: value })
        reload()
    }

    const updateUserWedding = async (value) => {
        await api.public.wedding.update(wedding.id, { name: value })
        reload()
    }

    const currentOffer = getOfferFromWedding(wedding)
    console.log(currentOffer, wedding, user,stats)
    //const maxStorage = currentOffer?.maxStorage
    //const maxGuests = currentOffer?.maxGuests
    //const maxBulks = currentOffer?.maxBulks

    return <div className="user-profile">
        <Header onProfilPage />

        <div className="user-edit-container">

            <div className="identity-edit">
                <h2 className="section-heading">
                    <Trans>My profile</Trans>
                </h2>
                <InlineEditor inlineLabel={user?.name || "user name"} name="userName" onSubmit={updateUserName} />
                <InlineEditor inlineLabel={user?.email} name="userEmail" onSubmit={updateUserEmail} />


            </div>
            {isOwner(user, wedding?.id) && 
            <div className="my-wedding">
                <h2 className="section-heading">
                    <Trans>My wedding</Trans>
                </h2>
                <div>
                    <InlineEditor inlineLabel={user?.Wedding?.name} name="userWedding" onSubmit={updateUserWedding} />
                </div>
            </div>
            }
            {/*<div className="contract-edit">
                <h2 className="section-heading">
                    <Trans>My offer</Trans>
                </h2>
                <div className="offer-container">
                    <div className="my-offer">
                        <span className="current-offer"><Trans >{currentOffer?.type}</Trans></span>
                        <span><Trans i18nKey="storage_count" count={maxStorage}>Storage: {{maxStorage}}mo</Trans></span>
                        <span><Trans i18nKey="guest_count" count={maxGuests} >{{maxGuests}} Guest</Trans></span>
                        <span><Trans i18nKey="bulks_count" count={maxBulks}>{{maxBulks}} album download</Trans></span>
                    </div>

                    {currentOffer?.type === "FREE" &&
                        <div className="change-offer">
                            <Link to={`/create/${wedding?.id}/choose-offer`} className="offer-page-link"><Trans>Change offer</Trans> </Link>
                        </div>}

                </div>
            </div>
*/}
            {(isOwner(user, wedding?.id) && !errorStats ) && <div className="my-stats">
                <h2 className="section-heading">
                    <Trans>My stats</Trans>
                </h2>
                <div className="stats-container">
                    <div className="storage-stats">
                        <label><Trans>Storage space used</Trans></label>
                        <span className="count-item">{stats?.storageMax && formatStorage(stats?.storage)}</span>
                        <div className="stats-bar-container">
                            <ProgressBar completion={stats?.storage / stats?.storageMax} />
                        </div>
                    </div>
                    <div className="guest-stats">
                        <label><Trans>Number of Guest</Trans></label>
                        <span className="count-item">{stats?.guestsCount}</span>
                        <div className="stats-bar-container">
                            <ProgressBar completion={stats?.guestsCount / stats?.guestsMax} />
                        </div>
                    </div>
                    <div className="bulks-stats">
                        <label><Trans>Downloaded Albums</Trans></label>
                        <span className="count-item">{stats?.bulks}</span>
                        <div className="stats-bar-container">
                            <ProgressBar completion={stats?.bulks / stats?.bulksMax} />
                        </div>

                    </div>
                </div>
            </div>}
        </div>
    </div>
}

export default UserProfile