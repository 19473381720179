
import "./style.css"

const Title = () => {
    return <div>
        <h2 className="Title">
            <span className="weddi">Weddi'</span>
            <span className="up">Up</span>
        </h2>
    </div>
}
export default Title