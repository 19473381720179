
const UNITS = {
    "ko": [999, 999999],
    "Mo": [1000000, 999999999],
    "Go": [1000000000, 999999999999],
    "To": [1000000000000, 999999999999999]
}
const EXPONENTS = {
    "ko": 10**3,
    "Mo": 10**6,
    "Go": 10**9,
    "To": 10**12
}

const formatStorage = (storage, digit = 2) => {
    const unit = Object.entries(UNITS).find(([key, [start, stop]]) => start <= storage && stop >= storage)?.[0]
    return `${(storage / EXPONENTS[unit] ).toFixed(digit)}${unit}`
}

export default formatStorage;