import { Button, Card } from "@mui/material"

import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import api from "../../../api"
import Input from "../../../components/Input";
import Error from "../../../components/Error";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const CreatePassword = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [error, setError] = useState("")
    const { userId } = useParams()
    let [searchParams] = useSearchParams();
    const createPassword = async (evt) => {
        evt.preventDefault()
        setError("")
        try {
            const { password } = evt.target
            await api.public.users.updatePassword(userId, { password: password.value, activationCode: Number(searchParams.get("activationCode")) })
            navigate("/authentication/login")
        } catch(error) {
            setError("unable to create your password")
        }
        
    }


    return <form className="form" method="post" onSubmit={createPassword} >
        <Error errorMsg={error} />
        <Card className="formContainer">
            <h2 className=""><Trans>Create your password</Trans></h2>
            <Input placeholder={t("Enter a password")} type="password" label={t("Enter a password")} name="password" />

            <Button type="submit" color="success" variant="contained"><Trans>Validate</Trans></Button>
        </Card>
    </form>
}

export default CreatePassword