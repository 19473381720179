import "./style.css"
import { useState } from "react"
import Input from "../Input"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useTranslation } from "react-i18next";


const InlineEditor = (props) => {
    const {t} = useTranslation()
    const { inlineLabel } = props
    const [editor, setEditor] = useState(false)

    const submit = async (evt) => {
        evt.preventDefault()
        const form = evt.currentTarget
        const value = form[props.name].value
        props.onSubmit(value)
        setEditor(false)

    }

    return <div>
        {!editor &&
            <div className="editor-container">
                {inlineLabel}
                <ModeEditIcon fontSize="small" onClick={() => setEditor(true)} className="edit-icon" />
            </div>}
        {
            editor &&
            <form className="editor-container" onSubmit={submit}>
                <div className="input-container">
                    <Input placeholder={inlineLabel} defaultValue={inlineLabel} name={props.name} size="small" />
                </div>

                <div className="btn-container">
                    <button className="valid-btn" type="submit">{t("validate")}</button>
                    <button className="cancel-btn" type="cancel" onClick={() => setEditor(false)} >{t("cancel")}</button>
                </div>
            </form>
        }

    </div>
}

export default InlineEditor